
<script>
import Modal from '@/components/Modal/Modal'
import BfInput from '@/components/BfInput/BfInput'
import BfDatePicker from '@/components/BfDatePicker/BfDatePicker'
import updateParticipant from '@/mixins/mutations/updateParticipant'
import linkParticipantToStudy from '@/mixins/mutations/linkParticipantToStudy'
import { formatDateForAPI } from '@/utils/date'
import { mapActions } from 'vuex'
import GET_COHORTS_QUERY from '@/graphql/cohorts/GetStudyCohortsQuery.graphql'
import { logError } from '@/utils/logging'

export default {
  name: 'InviteParticipantModal',
  components: {
    Modal,
    BfInput,
    BfDatePicker
  },
  mixins: [ updateParticipant, linkParticipantToStudy ],
  props: {
    study: {
      type: Object,
      default: () => {}
    },
    participant: {
      type: Object,
      default: () => {}
    }
  },
  apollo: {
    cohorts() {
      return {
        query: GET_COHORTS_QUERY,
        update: data => data.getStudyCohorts,
        variables: {
          studyId: this.study.id
        },
        error (error) {
          logError(error, 'cohort query')
        },
        skip() {
          return !this.study.id
        }
      }
    }
  },
  data() {
    return {
      emailAddress: '',
      emailError: '',
      birthdateError: '',
      dateOfBirth: '',
      loading: false,
      invited: false,
      error: false
    }
  },
  computed: {
    formattedDate() {
      return formatDateForAPI(this.dateOfBirth)
    },
    ppmiOnlineCohort() {
      return this.cohorts.find(cohort => cohort.name === 'Undefined')
    }
  },
  methods: {
    ...mapActions(['addToastMessage']),
    retryInvite() {
      this.error = false
      this.dateOfBirth = ''
      this.emailAddress = ''
    },
    async inviteParticipant() {
      if (this.birthdateError) this.birthdateError = ''
      if (this.emailError) this.emailError = ''

      if (this.participant.birthDate !== this.formattedDate) {
        this.birthdateError = 'DOB must match participant’s profile'
      } else {
        this.loading = true

        try {
          // if the email entered doesn't match the participants, update it.
          if (this.participant.emailAddress !== this.emailAddress) {
            await this.updateParticipant({
              ...this.participant,
              emailAddress: this.emailAddress,
              requireUniqueEmail: true
            }, true)
          }
          await this.linkParticipantToStudy(
            this.participant.id,
            this.study.id,
            this.ppmiOnlineCohort.id
          )
          this.$emit('participantInvited')
          this.invited = true
          this.loading = false
        } catch (e) {
          if (e.message && e.message.includes('email')) {
            this.emailError = 'This email address is already being used for a PPMI Online account'
          } else {
            this.error = true
          }
          this.loading = false
        }
      }
    }
  }
}
</script>

<template>
  <ValidationObserver v-slot="{ invalid }">
    <Modal
      v-loading="loading"
      class="invite-participant-modal"
      :title="`Invite to ${study.name}`"
      fix-header-and-footer
      @close="$emit('close')"
    >
      <template v-slot:content>
        <template v-if="!invited && !error">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|email"
          >
            <BfInput
              v-model="emailAddress"
              :error="emailError || errors[0]"
              label="Email Address"
              instructions="The email provided below will be used as the participant’s user name for PPMI Online.
            This must be a unique email address and cannot be shared between multiple participants."
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
          >
            <BfDatePicker
              v-model="dateOfBirth"
              :error="birthdateError || errors[0]"
              label="Confirm Date of Birth"
              instructions="This will be used to ensure this is the correct participant.
            It will not be shared with PPMI Online."
            />
          </ValidationProvider>
        </template>
        <template v-else-if="invited">
          <div class="sent-invite">
            <h4>Invitation Sent!</h4>
            <p>
              An invitation has been sent to {{ emailAddress }}.
              Please inform the participant that the invitation will arrive in a few minutes.
              If the email was not received:
            </p>
            <ul>
              <li>Ask participant to check spam or junk folder</li>
              <li>Ask participant to check Updates, Promotions or Forums folder if using Google Gmail</li>
            </ul>
            <p>
              <strong>
                If the email does not arrive within about 10 minutes,
                please contact support@evidation.com for assistance.
              </strong>
            </p>
          </div>
        </template>
        <template v-else-if="error">
          <h4>An Error Occurred</h4>
          <p>An error occurred and the PPMI Online email could not be sent. Please try again.</p>
          <p>
            <strong>If this problem persists, contact
              <a href="mailto:support@evidation.com">support@evidation.com</a>
              for assistance.</strong>
          </p>
        </template>
      </template>
      <template v-slot:actions>
        <el-button
          v-if="!invited && !error"
          type="primary"
          :disabled="invalid"
          @click="inviteParticipant"
        >
          Send Invitation
        </el-button>
        <el-button
          v-else-if="error"
          type="primary"
          @click="retryInvite"
        >
          Retry Invitation
        </el-button>
        <el-button
          :type="invited ? 'primary' : 'outline'"
          @click="$emit('close')"
        >
          {{ invited ? 'Close' : 'Cancel' }}
        </el-button>
      </template>
    </Modal>
  </ValidationObserver>
</template>

<style lang="scss">
.invite-participant-modal {
  h4 {
    margin: 0;
    @include text-style('title', 'extra-small', 'bold');
  }
  p, ul {
    @include text-style('paragraph', 'small', 'regular');
  }
  a {
    text-decoration: none;
    color: $dopamine;
  }
  .form-field {
    margin-bottom: 1rem;
  }
}
</style>
