import REMOVE_PARTICIPANT_FROM_STUDY from '@/graphql/participants/RemoveParticipantFromStudy.graphql'
import GET_PARTICIPANT_STUDIES from '@/graphql/participants/GetParticipantStudies.graphql'
import { logError } from '@/utils/logging'
export default {
  methods: {
    /**
         * Remove participant from study.
         * @param {string} participantId - the id of the participant
         * @param {string} studyId - the id of the study to remove
         * @returns {Promise}
         */
    removeParticipantFromStudy(
      participantId,
      studyId
    ) {
      return this.$apollo.mutate({
        mutation: REMOVE_PARTICIPANT_FROM_STUDY,
        variables: {
          participantId: participantId,
          studyId: studyId
        },
        refetchQueries: [{
          query: GET_PARTICIPANT_STUDIES,
          update: data => data.getParticipantStudies,
          pollInterval() {
            // Apollo documentation lists setting 0 for no-polling, but vue-apollo throws an error. Use null instead.
            return this.participantStudiesPollInterval || null
          },
          variables: {
            participantId: this.$route.params.participantId
          },
          error (error) {
            logError(error, 'participant studies query')
          }
        }]
      })
    }
  }
}
