<script>
import StatusIcon from '@/components/StatusIcon/StatusIcon'
import StatusPill from '@/components/StatusPill/StatusPill'
import { formatDateForDisplay } from '@/utils/date'
import participantStudies from '@/mixins/queries/participantStudies'
import studies from '@/mixins/queries/studies'
import detectModule from '@/mixins/detectModule'
import InviteParticipantModal from '@/components/InviteParticipantModal/InviteParticipantModal'
import { Features, ParticipantProtocolStatus } from '@/utils/constants'
import ContextMenuBasic from '@/components/ContextMenuBasic/ContextMenuBasic'
import removeParticipantFromStudy from '@/mixins/mutations/removeParticipantFromStudy'

export default {
  name: 'ParticipantStudies',
  components: {
    StatusIcon,
    StatusPill,
    InviteParticipantModal,
    ContextMenuBasic
  },
  mixins: [participantStudies, detectModule, studies, removeParticipantFromStudy],
  props: {
    participant: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      inviteModalVisible: false,
      participantStudiesPollInterval: null,
      ParticipantProtocolStatus
    }
  },
  computed: {
    participantHasActiveCognitoAccount() {
      return this.participant.isActive === true
    },
    // clinic study the participant should be pending for
    clinicStudy() {
      return this.participantStudies.find(study => study.name.includes('Clinic')) || {}
    },
    // ppmi online study, will only be on the participant if they've been invited at least
    participantOnlineStudy() {
      return this.participantStudies.find(study => study.name.includes('Online'))
    },
    // the general PPMI Online study,
    onlineStudy() {
      return this.studies.find(study => study.name === 'PPMI Online')
    },
    userIsInvitedLabel() {
      if (this.isEDCActive && this.enrollmentDate) {
        return `Invited ${this.participant.emailAddress} on ${this.enrollmentDate}`
      }
      return false
    },
    enrollmentDate() {
      if (this.participantOnlineStudy) {
        return formatDateForDisplay(this.participantOnlineStudy.enrollmentStatusDate, '/')
      }

      return ''
    },
    isInviteOnlineSupported() {
      return this.$store.getters.supportedFeatures.includes(Features.INVITE_TO_ONLINE)
    },
    participantOnlineStudyStatus() {
      if (!this.participantOnlineStudy) {
        return 'not-started'
      } else if (this.participantOnlineStudy.enrollmentStatus === 'pending' &&
          this.participantHasActiveCognitoAccount) {
        return 'account-created'
      } else if (this.participantOnlineStudy.enrollmentStatus === 'pending') {
        return 'invite-pending'
      }

      return this.participantOnlineStudy.enrollmentStatus
    },
    participantClinicStudyStatus() {
      return this.clinicStudy?.enrollmentStatus
    }
  },
  watch: {
    participantOnlineStudy() {
      if (this.participantOnlineStudyStatus === ParticipantProtocolStatus.INVITE_PENDING) {
        this.participantStudiesPollInterval = 30000
      }
    }
  },
  methods: {
    handleParticipantInvited() {
      this.$apollo.queries.participantStudies.refetch()
    },
    async cancelInvitation(participantId, studyId) {
      try {
        await this.removeParticipantFromStudy(
          participantId,
          studyId)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<template>
  <div
    v-loading="!participantStudies.length || !participant.id"
    class="participant-studies"
  >
    <h3>Study Participation</h3>
    <!-- PPMI ONLINE -->
    <div
      v-if="participantStudies.length && participant.id"
      class="participant-studies__study"
      :class="{'no-invite' : !userIsInvitedLabel}"
    >
      <div class="study-wrap">
        <div>
          <StatusIcon
            :status="participantOnlineStudyStatus"
          />
          <span
            class="participant-studies__study-title"
          >
            PPMI Online
          </span>
        </div>
        <span
          class="participant-studies__study-status participant-studies__with-menu"
        >
          <StatusPill
            v-if="participantOnlineStudy"
            :status="participantOnlineStudyStatus"
          />
          <el-button
            v-else-if="isEDCActive && isInviteOnlineSupported && onlineStudy"
            class="participant-studies__invite-link"
            type="ghost"
            @click="inviteModalVisible = true"
          >
            <SvgIcon name="add" />
            Invite
          </el-button>
          <ContextMenuBasic
            v-if="isInviteOnlineSupported && ParticipantProtocolStatus.INVITE_PENDING === participantOnlineStudyStatus"
          >
            <el-dropdown-item @click.native="inviteModalVisible = true">
              Resend Invitation
            </el-dropdown-item>
            <el-dropdown-item @click.native="cancelInvitation(participant.id, participantOnlineStudy.id)">
              Cancel Invitation
            </el-dropdown-item>
          </ContextMenuBasic>
        </span>
      </div>
      <span
        v-if="ParticipantProtocolStatus.INVITE_PENDING && userIsInvitedLabel"
        class="participant-studies__invite-text"
      >
        {{ userIsInvitedLabel }}
      </span>
    </div>
    <!-- CLINIC -->
    <div
      v-if="participantStudies.length && participant.id"
      class="participant-studies__study"
    >
      <div class="study-wrap">
        <div>
          <StatusIcon
            :status="clinicStudy && clinicStudy.enrollmentStatus"
          />
          <span class="participant-studies__study-title">
            PPMI Clinic
          </span>
        </div>
        <span class="participant-studies__study-status">
          <StatusPill
            :status="participantClinicStudyStatus"
            :text="participantClinicStudyStatus.replaceAll('_', ' ')"
          />
        </span>
      </div>
    </div>
    <InviteParticipantModal
      v-if="inviteModalVisible"
      :participant="participant"
      :study="onlineStudy"
      @close="inviteModalVisible = false"
      @participantInvited="handleParticipantInvited"
    />
  </div>
</template>

<style lang="scss">
.participant-studies {
  width: 100%;
  border: 1px solid $axon;
  background-color: $white-matter;
  margin-top: 2rem;
  min-height: 10rem;

  h3 {
    padding: 1.5rem 1rem;
    margin: 0rem;
    @include text-style('title', 'medium', 'medium');
  }

  &__invite-text {
    color: $hillcock;
    margin-left: 1.5rem;
    @include text-style('interface', 'small', 'small');
  }

  .svg-icon {
    display: inline-block;
    margin-right: .5rem;

    &.status {
      position: relative;
      top: 3px;
      &--withdrew{
        transform: rotate(180deg);
        fill: $error;
      }
      &--screen_failed{
        fill: $error;
      }
      &--screened{
        fill: $dopamine;
      }
    }
  }

  &__study {
    border-top: 1px solid $axon;
    padding: .5rem 1rem;

    @include text-style('interface', 'small', 'medium');

    .study-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .no-invite {
    .participant-studies__with-menu {
      top: 0;
    }
  }
  &__study--with-menu {
    border-top: 1px solid $axon;
    padding: .5rem 1rem;

    @include text-style('interface', 'small', 'medium');

    .study-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .participant-studies__study-status {
      margin-right: 0;
    }
  }
  &__invite-link {
    padding: .5rem 0 !important;

    svg {
      height: 1rem;
      width: 1rem;
    }
    span {
      @include text-style('interface', 'small', 'medium');
    }
  }
  &__study-title {
    display: inline-block;
  }

  &__study-status {
    display: inline-block;
    //margin-right: 2rem;
    padding-right: 3rem;
    min-width: 5.5rem;

    .status-pill {
      display: inline-flex;
    }
  }
  &__with-menu {
    align-items: center;
    position: relative;
    top: 8px;
    .status-pill {
      display: inline-flex;
    }
  }
}
</style>
