import GET_COHORTS_QUERY from '@/graphql/cohorts/GetStudyCohortsQuery.graphql'
import { getCohortNameById } from '@/utils/cohorts'
import { logError } from '@/utils/logging'

export default {
  apollo: {
    cohorts() {
      return {
        query: GET_COHORTS_QUERY,
        update: data => data.getStudyCohorts,
        variables: {
          studyId: this.$route.params.studyId
        },
        error (error) {
          logError(error, 'cohort query')
        }
      }
    }
  },
  data() {
    return {
      cohorts: []
    }
  },
  methods: {
    getCohortName(cohortId) {
      return getCohortNameById(cohortId, this.cohorts)
    }
  }
}
