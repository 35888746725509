import GET_PARTICIPANT_VISIT_WINDOWS_V2 from '@/graphql/participants/GetParticipantVisitWindowsV2Query.graphql'
import { logError } from '@/utils/logging'

export default {
  apollo: {
    /*
     * Fetch the list of visits (started & unstarted) for this participant.
     */
    participantVisits() {
      this.$store.dispatch('updateLoading', true)
      return {
        query: GET_PARTICIPANT_VISIT_WINDOWS_V2,
        variables: {
          participantId: this.$route.params.participantId,
          studyId: this.$route.params.studyId
        },
        update: data => data.getParticipantVisitWindowsV2,
        result (data) {
          const visitWindows = data?.data?.getParticipantVisitWindowsV2
          this.participantVisits = visitWindows ? visitWindows.flatMap(x => x.visits) : []
          this.$store.dispatch('updateLoading', false)
        },
        error (error) {
          logError(error, 'ParticipantDetail.vue participant visits query')
        }
      }
    }
  },
  data() {
    return {
      participantVisits: []
    }
  }
}
