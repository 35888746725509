
<script>
import PageHeader from './PageHeader'
import SkeletonBox from '@/components/SkeletonBox/SkeletonBox'

export default {
  components: {
    PageHeader,
    SkeletonBox
  },
  props: {
    participant: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: true
    },
    isEDCActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    backLink() {
      return {
        text: 'All Participants',
        route: this.isEDCActive
          ? { name: 'EDCHome' }
          : { name: 'studyParticipants', params: { ...this.$router.params } }
      }
    },

    tabLinks() {
      return [
        {
          text: 'Visit Schedule',
          route: {
            name: 'visitSchedule',
            params: { ...this.$route.params }
          },
          exact: true
        },
        {
          text: 'Participant Profile',
          route: {
            name: 'participantProfile',
            params: { ...this.$route.params }
          }
        }
      ]
    }
  }
}
</script>

<template>
  <PageHeader
    :back-link="backLink"
    :tab-links="isEDCActive ? tabLinks : []"
    :title="`ID: ${participant.customerDefinedId}`"
    class="participant-header"
  >
    <template v-slot:title>
      <h1 class="participant-name">
        <SkeletonBox v-if="loading" />
        <span v-else>
          <span
            v-if="isEDCActive && participant.firstName"
            class="not-for-print first-last-name"
          >
            {{ participant.firstName | capitalize }} {{ participant.familyName | capitalize }}
            <span class="participant-id">({{ participant.customerDefinedId }})</span>
          </span>
          <span
            v-else
            class="not-for-print"
          >
            ID: {{ participant.customerDefinedId }}
          </span>
        </span>
      </h1>
    </template>
    <slot />
  </PageHeader>
</template>

<style lang="scss">
.header.participant-header {
  // Reset padding
  width: calc(100% + 3rem);
  margin: -1.5rem;
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
  background-color: $white-matter;
  display: grid;
  grid-template: auto / auto auto;

  .back-link {
    grid-row: 1;
    grid-column: 1;
  }

  .header__title-wrap {
    grid-row: 2;
    grid-column: 1;
  }

  .tabs {
    grid-row: 3;
    grid-column: 1;
  }

  .participant-header__actions {
    grid-row: 1 / span 3;
    grid-column: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .participant-id {
    @include text-style('title', 'large', 'regular');
  }

  &.header--ctms {
    .tabs {
      margin-bottom: 0;
    }
  }
}

/*
 * Define styles for a "participant details" pattern.
 */
.participant-details {
  grid-row: 3;
  grid-column: 1;
  width: 100%;
  margin: 0;
  margin-bottom: 1rem;
  @include text-style('interface', 'small', 'normal');
  line-height: 1.5rem;

  dt {
    @include text-weight('medium');
  }

  dd {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__field {
    display: grid;
    grid-template: 1fr / 4rem auto;
  }

  .skeleton-box {
    max-width: 10rem;
  }

  &__manage-site {
    display: inline;
    margin-left: .25rem;

    a {
      text-decoration: none;
    }

    .tooltip {
      width: max-content;
      padding: .5rem;
      background-color: $hillcock;
      @include text-style('interface', 'extra-small', 'medium');
      @include elevate(sm);

      // We need to override placement styles due to modifying the tooltip's padding
      &.right {
        left: calc(100% + .75rem);
        bottom: -.375rem;

        &::after {
          border-right-color: $hillcock;
          bottom: .5rem;
        }
      }
    }
  }
}
</style>
