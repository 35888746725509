import UPDATE_PARTICIPANT_MUTATION from '@/graphql/participants/UpdateParticipantMutation.graphql'
import GET_UNRESTRICTED_PARTICIPANT_QUERY from '@/graphql/participants/GetUnrestrictedParticipantQuery.graphql'
import { logError } from '@/utils/logging'

export default {
  methods: {
    /**
     * Update the Participant.
     * @param {object} participantWithNewValues - a participant object with updated values to save
     * @param {boolean} throwError - flag, if true, throw the caught error so we can catch it again per use-case
     * @returns {Promise}
     */
    updateParticipant(participantWithNewValues, throwError = false) {
      return this.$apollo.mutate({
        mutation: UPDATE_PARTICIPANT_MUTATION,
        variables: {
          ...participantWithNewValues,
          participantId: participantWithNewValues.id,
          studyId: this.$route.params.studyId
        },
        update: (store, { data: { updateParticipant } }) => {
          // update the 'GetParticipant*' queries to match the updated participant
          store.writeQuery({
            // participants can only be edited in the EDC so we can reliably assume that only the
            // getUnrestrictedParticipant query needs to be updated locally
            query: GET_UNRESTRICTED_PARTICIPANT_QUERY,
            variables: {
              participantId: participantWithNewValues.id,
              studyId: this.$route.params.studyId
            },
            data: {
              // Modify any values that may have been updated.
              getParticipantUnrestricted: {
                ...updateParticipant,
                cohortId: participantWithNewValues.cohortId,
                cohort: {
                  __typename: 'ParticipantCohort',
                  id: participantWithNewValues.cohortId,
                  /**
                   * if the cohort is an object, its a vanilla participant object, so get the name from the cohort
                   * otherwise, the cohort is most likely a string and coming from the participant profile
                   * TODO: we should have a transformation function to transform participants to/from their server
                   * response version to avoid having to do logic like this
                   */
                  name: typeof participantWithNewValues.cohort === 'object'
                    ? participantWithNewValues.cohort.name : participantWithNewValues.cohort,
                  description: '',
                  /**
                   * if the participant object has a subgroups array on it in the first level, we are upating it from
                   * the participant profile, so we need to possibly update the subgroups here. otherwise, just return
                   * the subgroups form the participants cohort
                   */
                  subgroups: participantWithNewValues.subgroups ? participantWithNewValues.subgroups.map(subgroup => {
                    const subgroupDetails = this.subgroupOptions.find(subgroupOption => (
                      subgroupOption.id === subgroup
                    ))
                    return {
                      __typename: 'Subgroup',
                      id: subgroup,
                      name: subgroupDetails ? subgroupDetails.name : ''
                    }
                  }) : participantWithNewValues.cohort.subgroups
                }
              }
            }
          })
        }
      })
        .catch(error => {
          logError(error, 'ParticipantDetail.vue update participant mutation')
          // TODO: use mixin for inline notifications? vuex store for inline notifications?
          // test to ensure notifications exist
          if (this.notifications && this.notifications.length < 1) {
            this.notifications.push({
              id: 0,
              type: 'error',
              title: '',
              message: 'There was an error saving the updated participant.'
            })
          }
          if (this.$refs.notifications) {
            // smooth scroll back up to notification area
            this.$refs.notifications.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
          }
          if (throwError) {
            throw error
          }
        })
    }
  }
}
