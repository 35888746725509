
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ParticipantStudy"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ParticipantStudy"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"enrollmentStatus"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"enrollmentStatusDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cohort"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ParticipantCohort"},"directives":[]}]}}]}}],"loc":{"start":0,"end":213}};
    doc.loc.source = {"body":"#import \"../cohorts/ParticipantCohortFragment.graphql\"\n\nfragment ParticipantStudy on ParticipantStudy {\n\tid,\n\tname,\n\tdescription,\n\tenrollmentStatus,\n  enrollmentStatusDate,\n\tcohort {\n    ...ParticipantCohort\n  }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../cohorts/ParticipantCohortFragment.graphql").definitions));


      module.exports = doc;
    
