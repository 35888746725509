import LINK_PARTICIPANT_TO_STUDY_MUTATION from '@/graphql/participants/LinkParticipantToStudy.graphql'

export default {
  methods: {
    /**
     * Link the participant to a cohort and/or enrollment subgroups.
     * @param {string} participantId - the id of the participant to link
     * @param {string} studyId - the id of the study to link the participant to
     * @param {string} cohortId - the id of the cohort the participant is associated with
     * @param {array} subgroupIds - an array of subgroup ids the participant is associated with
     * @returns {Promise}
     */
    linkParticipantToStudy(
      participantId,
      studyId,
      cohortId,
      subgroupIds
    ) {
      return this.$apollo.mutate({
        mutation: LINK_PARTICIPANT_TO_STUDY_MUTATION,
        variables: {
          participantId: participantId,
          studyId: studyId,
          cohortId: cohortId,
          subgroupIds: subgroupIds
        }
      })
    }
  }
}
