import GET_PARTICIPANT_STUDIES from '@/graphql/participants/GetParticipantStudies.graphql'
import { logError } from '@/utils/logging'

export default {
  apollo: {
    participantStudies() {
      return {
        query: GET_PARTICIPANT_STUDIES,
        update: data => data.getParticipantStudies,
        pollInterval() {
          // Apollo documentation lists setting 0 for no-polling, but vue-apollo throws an error. Use null instead.
          return this.participantStudiesPollInterval || null
        },
        variables: {
          participantId: this.$route.params.participantId
        },
        error (error) {
          logError(error, 'participant studies query')
        }
      }
    }
  },
  data() {
    return {
      participantStudies: []
    }
  }
}
