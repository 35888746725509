<template>
  <el-dropdown
    trigger="click"
    class="context-menu"
    v-bind="$attrs"
    @visible-change="handleVisibleChange"
  >
    <el-button
      type="menu"
      :class="{ 'active' : menuIsVisible }"
    >
      <div class="icon-wrap">
        <SvgIcon name="overflow-menu" />
      </div>
    </el-button>
    <el-dropdown-menu class="context-menu-content">
      <slot />
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  data() {
    return {
      menuIsVisible: false
    }
  },
  methods: {
    handleVisibleChange(isVisible) {
      this.menuIsVisible = isVisible
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  .context-menu {
    .active {
      background-color: #ffffff
    }
  }
  .el-dropdown {
    position: absolute;
    right: -1rem;
  }
  .context-menu {
    height: auto;
    width: auto;

    .el-button{
      min-height: 0;
    }
    .el-button--menu {
      border: none;
      &:not(:disabled):hover {
        background-color: unset;
      }
      &:not(:disabled):active {
        background-color: unset;
      }
      &:not(:disabled):focus {
        background-color: unset;
      }
    }
  }
}
</style>
