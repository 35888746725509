<script>
import { ParticipantProtocolStatus } from '@/utils/constants'

export default {
  name: 'StatusPill',
  props: {
    status: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    statusText() {
      switch (this.status) {
        case ParticipantProtocolStatus.INVITE_PENDING:
          return 'Invitation Pending'
        case ParticipantProtocolStatus.ACCOUNT_CREATED:
          return 'Account Created'
        default:
          return this.status
      }
    }
  }
}
</script>

<template>
  <div :class="`status-pill status-pill--${status}`">
    {{ text ? text : statusText }}
  </div>
</template>

<style lang="scss">
.status-pill {
  text-transform: capitalize;
  @include text-style('interface', 'small', 'regular');
  height: 2rem;
  padding: 0 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;

  &--account-created {
    background-color: lighten($dopamine, 40%);
  }
  &--pending {
    background-color: lighten($warning, 40%);
  }
  &--screened {
    background-color: lighten($dopastat, 40%);
  }

  &--reopened{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: $info-purple;
  }
  &--invite-pending {
    background-color: lighten($warning, 40%);
  }
  &--declined,
  &--screen_failed,
  &--excluded,
  &--withdrew {
    background-color: lighten($error, 40%);
  }
  &--enrolled,
  &--complete {
    background-color: lighten($success, 60%);
  }
}
</style>
